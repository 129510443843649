// @ts-ignore
import store from '@/application/store/index';
import {NavigationGuard} from "vue-router/types/router";

const authorisationGuard: NavigationGuard = (to, from, next) => {
  if (!store.getters['authorisation/isLoggedIn']) {
    next({
      name: 'login',
    });
    return;
  }
  next();
}
export default authorisationGuard;
