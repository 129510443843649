const light = {
  primary: '#125488',
  secondary: '#000000',
  background: '#e5e5e5'
};

const dark = {
  primary: '#125488',
};

export { light, dark };
