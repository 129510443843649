import AuthorisationGuard from '@/router/guards/AuthorisationGuard';
import {RouteConfig} from "vue-router";

const globalRoutes: RouteConfig = {
  path: '',
  beforeEnter: AuthorisationGuard,
  component: () => import('@/templates/Default.vue'),
  children: [
    {
      path: 'search',
      name: 'search',
      component: () => import('@/modules/search/views/Search.vue'),
    },
    {
      path: '404',
      name: '404',
      component: () => import('@/modules/errorHandling/views/PageNotFound.vue'),
    },
    {
      path: '403',
      name: '403',
      component: () => import('@/modules/errorHandling/views/PageForbidden.vue'),
    },
  ],
};

export default globalRoutes
