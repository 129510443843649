import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// @ts-ignore
import authorisation from '@/modules/authorisation/store/authorisation.js';
// @ts-ignore
import error from '@/modules/errorHandling/store/error.js';
// @ts-ignore
import settings from './modules/settings.js';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        'authorisation',
        'settings',
      ],
    }),
  ],
  modules: {
    authorisation,
    error,
    settings,
  },
});
