import actions from './actions.json';
import authorisation from './authorisation.json';
import home from './home.json';
import global from './global.json';
import locale from './locale.json';
import user from './user.json';
import validation from './validation.json';
import errors from './errors.json';
import puzzle from './puzzle.json';

export default {
  actions,
  authorisation,
  locale,
  user,
  validation,
  errors,
  home,
  global,
  puzzle,
};
