import AuthorisationGuard from '@/router/guards/AuthorisationGuard';
import {RouteConfig} from "vue-router";

const authorisedRoutes: RouteConfig = {
  path: '',
  beforeEnter: AuthorisationGuard,
  component: () => import('@/templates/Default.vue'),
  children: [
    {
      path: '',
      name: 'home',
      component: () => import('@/modules/home/views/Home.vue'),
    },
    {
      path: 'puzzles',
      name: 'puzzle.index',
      component: () => import('@/modules/puzzle/views/Index.vue'),
    },
    {
      path: 'puzzle/:puzzleId',
      name: 'puzzle.show',
      component: () => import('@/modules/puzzle/views/PuzzleDetail.vue'),
    },
  ],
};

export default authorisedRoutes;
