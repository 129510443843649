import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router'
import authorisation from './routes/authorisation';
import authorised from './routes/authorised';
import global from './routes/global';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    redirect: {name: 'home'},
  },
  authorisation,
  authorised,
  global,
  {
    path: '*',
    redirect: '404',
  },
];

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
});
